import React from "react"

import Layout from "../layouts/layout"

function NotFoundPage() {
    return(
        <Layout>
            <h1 className="mt-3">404: Page Not Found</h1>
            <p>ページが見つかりませんでした。</p>
        </Layout>
    )
}

export default NotFoundPage